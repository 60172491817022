import { render, staticRenderFns } from "./TableAltPreview.vue?vue&type=template&id=c8876dec&scoped=true&"
import script from "./TableAltPreview.vue?vue&type=script&setup=true&lang=ts&"
export * from "./TableAltPreview.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./TableAltPreview.vue?vue&type=style&index=0&id=c8876dec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8876dec",
  null
  
)

export default component.exports